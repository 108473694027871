import {getAsYouType, getExample} from "awesome-phonenumber";
import React, {useCallback, useEffect, useId, useMemo, useRef} from "react";
import {useDisclosure} from "src/hooks/useDisclosure";

import ErrorMessage from "../components/ErrorMessage";
import Label from "../components/Label";
import {inputStyles} from "../utils/commonStyles";
import {RegionData} from "./utils/getRegionData";

export type PhoneInput = {
  className?: string;
  label?: string;
  ariaLabel?: string;
  handleChange: (input: string) => void;
  phoneNumber?: string;
  errorMsg?: string;
  setValidation?: (validate: boolean) => void;
  isRequired?: boolean;
  region: RegionData;
  onEnter?: (input?: string) => unknown;
  onBlur?: () => void;
  "data-cy"?: string;
};

const PhoneInput: React.FC<PhoneInput> = ({
  className,
  label,
  ariaLabel,
  handleChange,
  onEnter,
  onBlur,
  phoneNumber,
  errorMsg,
  setValidation,
  region,
  "data-cy": dataCy,
}) => {
  const focusState = useDisclosure();

  const ref = useRef<HTMLInputElement | null>(null);
  const isBadInput = typeof errorMsg === "string" && errorMsg.length > 0;

  const inputId = useId();
  const labelId = useId();

  const isLabelSmall =
    focusState.isOpen || (typeof phoneNumber === "string" && phoneNumber.length > 0);

  const slug = region.slug;

  const examplePhoneNumber = useMemo(() => getExample(slug), [slug]);
  const ayt = useMemo(() => getAsYouType(slug), [slug]);
  const formatNumber = useCallback(
    (digit?: string) => {
      if (!digit) return "";

      const phoneNumberDigits = digit.split("").filter((x: string) => !isNaN(parseInt(x, 10)));

      return ayt.reset(phoneNumberDigits.join(""));
    },
    [ayt],
  );

  const onChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    e => {
      const formatedNumber = formatNumber(e.target.value);
      handleChange(formatedNumber || "");
    },
    [formatNumber, handleChange],
  );

  useEffect(() => {
    if (!isBadInput) {
      handleChange(formatNumber(phoneNumber) || "");
    }
  }, [formatNumber, handleChange, isBadInput, onBlur, phoneNumber, setValidation]);

  const handleOnBlur: React.FocusEventHandler<HTMLInputElement> = () => {
    focusState.close();
    setValidation?.(true);
    onBlur?.();
  };

  const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = e => {
    if (e.key === "Enter") {
      if (!isBadInput) {
        onEnter?.(e.currentTarget.value);
      }

      setValidation?.(true);
    }
  };

  return (
    <div className="pos-r w-full">
      <div
        onClick={() => ref.current?.focus()}
        className={`${inputStyles.wrapper} ${borderStyles(focusState.isOpen, isBadInput)} ${
          className || ""
        } ${label ? "pt7" : ""} ${inputBgColor(focusState.isOpen, isBadInput)} cursor-text`}
      >
        <Label label={label} id={labelId} htmlFor={inputId} isLabelSmall={isLabelSmall} />
        <div className={`flex w-full pos-r ${label && "top-1.5"}`}>
          <input
            id={inputId}
            ref={ref}
            aria-label={ariaLabel}
            aria-labelledby={labelId}
            placeholder={isLabelSmall ? examplePhoneNumber.number?.national : ""}
            className={`${inputStyles.input} font-i`}
            value={phoneNumber}
            onChange={onChange}
            onBlur={handleOnBlur}
            onKeyDown={onKeyDown}
            onFocus={focusState.open}
            data-cy={dataCy}
          />
        </div>
      </div>
      <ErrorMessage msg={errorMsg} />
    </div>
  );
};

const borderStyles = (focused: boolean, error: boolean) =>
  error
    ? "border-2 border-solid border-error-700"
    : focused
    ? "border-blue-500 border-2 border-solid"
    : "border-2 border-solid border-gray-100";

const inputBgColor = (focused: boolean, error: boolean) =>
  error ? "bg-white" : focused ? "bg-blue-100" : "bg-gray-100";

export default PhoneInput;
